<template>
  <div>
    <b-form-checkbox
      v-model="item.checked"
      @change="$emit('update-parent', item)"
    >
      {{ item.name }}
    </b-form-checkbox>

    <div v-if="item.children && item.children.length" :class="{ 'ml-3': level === 0 }">
      <menu-item
        v-for="child in item.children"
        :key="child.id"
        :item="child"
        :level="level + 1"
        @update-parent="$emit('update-parent', child)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    item: Object,
    level: Number
  }
}
</script>